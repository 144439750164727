import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, from, lastValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { tap } from 'rxjs/operators';
import { AuthFirebaseService } from './marketplace/service/auth-firebase.service';
import { UserPreferenceService } from './marketplace/service/user-preferences.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    @Inject(PLATFORM_ID) private platform,
    private router: Router,
    private userPreferenceService: UserPreferenceService,
    private authFireBase: AuthFirebaseService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handle(request, next));
  }

  async handle(request: HttpRequest<any>, next: HttpHandler) {
    const re = '/v1/auth';
    if (isPlatformBrowser(this.platform)) {
      const token = this.authFireBase.token;
      const isAnybuddyApi =
        request.url.startsWith(environment.organiserApiUrl) ||
        request.url.startsWith(environment.bookingApiUrl);
      if (isAnybuddyApi && token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          },
          withCredentials: true
        });
      }
    }
    request = request.clone({
      setHeaders: await this.getHeaders()
    });
    // Exclude interceptor for login request:
    if (request.url && request.url.search(re) === -1) {
      return await lastValueFrom(
        next.handle(request).pipe(
          tap({
            error: (error) => {
              console.error('http request error', error);
              if (error instanceof HttpErrorResponse) {
                console.warn(`http request status ${error.status}`);
                if (error.status === 401) {
                  this.router.navigate(['/logout']);
                }
              }
            }
          })
        )
      );
    } else {
      return await lastValueFrom(next.handle(request));
    }
  }

  async getHeaders() {
    const headers = {
      'Accept-Language': this.userPreferenceService.getLocale(),
      'X-Anybuddy-Clientid': '15'
    };
    return headers;
  }
}
