import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NewMailCode } from '../models/newMail';
import { ResetPassword } from '../models/resetPassword';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private headers = new HttpHeaders().set('Content-Type', 'application/json');
  private usersUrl = environment.organiserApiUrl + '/v1/users';
  private loginUrl: string = environment.organiserApiUrl + '/v1/auth';

  constructor(private http: HttpClient) {}

  getMe(): Observable<User> {
    return this.http.get<User>(this.usersUrl + '/me');
  }

  updateProfile(
    firstName: string,
    lastName: string,
    email: string
  ): Observable<User> {
    return this.http.put<User>(
      this.usersUrl + '/me',
      JSON.stringify({ firstName, lastName, email }),
      { headers: this.headers }
    );
  }

  updateProfilePhone(phoneNumber: string): Observable<User> {
    return this.http.put<User>(
      this.usersUrl + '/me',
      JSON.stringify({ phoneNumber }),
      { headers: this.headers }
    );
  }

  createPassword(password: string): Observable<any> {
    return this.http.post<User>(
      `${environment.organiserApiUrl}/v1/auth/create_password`,
      JSON.stringify({ password }),
      { headers: this.headers }
    );
  }

  addSocialProvider(provider: string, token: string): Observable<User> {
    return this.http.post<User>(
      this.usersUrl + '/me/sn',
      JSON.stringify({ provider, token }),
      { headers: this.headers }
    );
  }

  authWithProvider(provider: string, token: string): Observable<User> {
    return this.http.post<User>(
      this.usersUrl + '/auth',
      JSON.stringify({ provider, token }),
      { headers: this.headers }
    );
  }

  verifUserMail(token: string): Observable<User> {
    return this.http.get<User>(this.usersUrl + `/me/confirm-email/${token}`);
  }

  resetPassword(password: ResetPassword, token: string): Observable<any> {
    return this.http.put(
      `${this.loginUrl}/reset_password/${token}`,
      JSON.stringify(password),
      { headers: this.headers }
    );
  }

  verifyCode(email: string, code: string): Observable<User> {
    const newMail = new NewMailCode(email, code);
    return this.http.put<User>(
      `${this.usersUrl}/me/confirm-email`,
      JSON.stringify(newMail),
      { headers: this.headers }
    );
  }

  deleteUser(userId: any): Observable<any> {
    return this.http.delete<any>(`${this.usersUrl}/${userId}`);
  }

  updateUserPreferences(data: any): Observable<any> {
    return this.http.post(
      `${this.usersUrl}/me/preferences`,
      JSON.stringify(data),
      { headers: this.headers }
    );
  }

  getUserPreferences(): Observable<any> {
    return this.http.get(`${this.usersUrl}/me/preferences`, {
      headers: this.headers
    });
  }

  removeEmailFromMailingList(email: string): Observable<any> {
    return this.http.delete(
      `${this.usersUrl}/me/preferences/mailinglist/${email}`
    );
  }
}
